export const DESCRIPTIVE_BADGE_VARIANTS = {
  PRIMARY: 'primary',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  DEFAULT: 'default',
  DISABLED: 'disabled',
  REGULAR: 'regular',
  SECONDARY: 'secondary'
};

export const DESCRIPTIVE_BADGE_SIZES = {
  XSMALL: 'xsmall',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

export const TOOLTIP_VARIANTS = {
  LIGHT: 'light',
  DARK: 'dark',
  STONE: 'stone'
};

export const TOOLTIP_POSITIONS = {
  BOTTOM_CENTER: 'bottomCenter',
  BOTTOM_LEFT: 'bottomLeft',
  BOTTOM_RIGHT: 'bottomRight'
};

export const PUPPY = 'puppy';
